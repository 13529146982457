.header {
  margin-top: 32px;
  text-align: center;
}
.imgWrapper {
  height: 100px;
  border: solid 1px #dce0e3;
  margin: 16px 2px 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgWrapper :global(img) {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
  object-fit: contain;
}
.name {
  font-size: 12px;
  color: #596066;
  margin: 0 2px 4px;
}
.price {
  font-size: 12px;
  font-weight: bold;
  color: #596066;
  margin: 0 2px 42px;
}
.link {
  text-decoration: none;
}
